<script>
import BaseInput from "@/components/BaseInput.vue";

export default {
  name: "AboutMe",

  data() {
    return {
      email: "",
    };
  },

  components: {
    BaseInput,
  },
};
</script>
<template>
  <div id="contact-me-page">
    <section class="page-title page-title-1 image-bg overlay">
      <div class="background-image-holder">
        <img
          alt="Background Image"
          class="background-image"
          src="@/index/assets/img/meet-kristen.jpg"
        />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h1 class="uppercase mb0">Meet Kristen</h1>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 col-md-offset-1">
            <div class="row mb32">
              <div class="col-sm-5 text-center mb-xs-48">
                <img
                  alt="Background Image"
                  class="background-image mb32"
                  src="@/index/assets/img/about-me.jpg"
                />
              </div>
              <div class="col-sm-6 col-sm-offset-1">
                <p class="lead">
                  With a focus on detail and clear communication, distinguished
                  properties she has sold include single-family homes, condos,
                  lofts, multi-unit buildings, and new construction. Her client
                  list includes first time buyers, seasoned investors,
                  professional property developers and large scale builders. In
                  addition to helping agents, Kristen has been personally
                  investing in real estate, keeping a pulse on emerging markets.
                </p>
                <p class="lead">
                  Prior to real estate, Kristen graduated from Kent State
                  University with an Honor's degree in Merchandising and Visual
                  Design. Kristen developed her keen business acumen and
                  negotiating expertise working as a merchant buyer for a
                  national clothing retailer. With a strong interest in
                  architecture, design, and development, Kristen provides vision
                  and resources to get the most for your money.
                </p>
                <p class="lead">
                  Originally from the Midwest, Kristen resides in the Golden
                  Gate Heights neighborhood. She spearheads philanthropic and
                  social committees with annual events at the San Francisco Food
                  Bank, Habitat for Humanity, and Glide Memorial. One of
                  Kristen's most rewarding experiences has been her mentorship
                  role with Big Brothers, Big Sisters where she and her little
                  sister Aalisha enjoy adventures around the City with her
                  rescue dog, Boomer. Kristen has played on top-ranking USTA
                  tennis teams and at the Golden Gate Women’s Soccer Club. She
                  is currently learning to kiteboard and has rediscovered her
                  love of playing classical piano.
                </p>
                <p>
                  <br />
                  <strong style="font-size: 14px">
                    Kristen Stuecher
                  </strong>
                  <br />
                  <span
                    >M: <a href="tel:415-948-4888">415-948-4888</a>&nbsp; |
                    &nbsp;DRE#: 01768022</span
                  >
                  <br>
                  <span>E: <a href="mailto:kristen.stuecher@compass.com">kristen.stuecher@compass.com</a> </span>
                </p>
                <!--end of modal video-->
              </div>
            </div>
          </div>
        </div>

        <!--end of row-->
      </div>
      <!--end of container-->
    </section>
    <!-- <section class="image-bg overlay">
      <div class="background-image-holder">
        <img
          alt="Background Image"
          class="background-image"
          src="@/index/assets/img/meet-kristen-top.jpg"
        />
      </div>
      <div class="container">
        <div class="row form-row">
          <div class="col-sm-6 text-center contact-me-col">
            <h3>Work with Kristen</h3>
            <p class="lead mb64">
              Kristen has more than a decade of Bay Area real estate sales
              experience and over $400 million in closed transactions. She has
              continuously ranked with top San Francisco real estate
              professionals in total sales production and among leading agents
              nationwide- all while having fun doing it!
            </p>
            <router-link to="/contact-us" class="btn btn-white btn-lg"
              >Contact Us</router-link
            >
          </div>
          <div class="col-sm-6 text-center newsletter-col">
            <h3>Newsletter</h3>
            <p class="lead">
              For exclusive news and market updates sign up for our newsletter.
            </p>
            <form action="">
              <div class="form-group">
                <BaseInput
                  v-model="email"
                  type="text"
                  class="validate-required validate-email"
                  placeholder="Email"
                />
              </div>
              <button class="btn btn-white btn-lg">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>
<style lang="scss">
.form-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
#contact-me-page {
  .lead {
    text-align: left;
  }
  .contact-me-col {
    padding: 64px;
    background: #383838b5;
  }

  .newsletter-col {
    border: 1px solid #e8e8e8;
    border-width: 1px 1px 1px 0;
    padding: 104px 64px;
  }
}
</style>
